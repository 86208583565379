import Vue from "vue";

export default {
    props: {
        item: {},
    },
    name: "slider",
    data() {
        return {
            activeIndex: 0,
            widthItemView: 0,
            heightItemView: 0,
            heightItemThumb: 0,
            index: null,
            videoEnded: true
        };
    },
    mounted() {
        Vue.nextTick(() => {
            this.widthItemView = this.$refs.viewContainer.offsetWidth;
            this.heightItemView = this.$refs.viewContainer.offsetHeight;
            if (this.$refs.itemThumb) {
                this.heightItemThumb = this.$refs.itemThumb[0].clientHeight;
            }
        });
    },
    methods: {
        changeActiveSlideIndex(index) {
            this.activeIndex = index;
        },
        changeIndex(direction) {
            try {
                this.$refs.video[0].pause()
                this.videoEnded = true;
            } catch (e) {}
            if (direction === "prev") {
                if (this.activeIndex > 0) {
                    this.activeIndex--;
                }
            } else if (direction === "next") {
                if (this.activeIndex < this.item.length - 1) {
                    this.activeIndex++;
                }
            }
        },
        handleVideoEnded() {
            this.videoEnded = true;
            this.$refs.video[0].pause()
        },
        handleVideoPlay() {
            this.videoEnded = false;
        },
        playVideo() {
            this.$refs.video[0].play()
        },
        pauseVideo() {
            this.$refs.video[0].pause()
            this.videoEnded = true;
        }
    },
};
